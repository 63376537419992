import { addMonths } from "date-fns";

const parseOrZero = (num) => {
  return num ? parseFloat(num) : 0;
};

const calcDiscountedMsrp = (msrp, dealerContribution) => {
  return (parseOrZero(msrp) - parseOrZero(dealerContribution)).toFixed(2);
};

const calcFinalDealerPrice = (discountedMsrp, tradeIn) => {
  return parseOrZero(discountedMsrp) - parseOrZero(tradeIn);
};

const calcNetCapCost = (
  finalDealerPrice,
  docFee,
  acquisitionFee,
  miscFees,
  rebateLeaseCash,
  downPayment,
  estimatedLeasePayment,
  unexplained
) => {
  return (
    parseOrZero(finalDealerPrice) +
    parseOrZero(docFee) +
    parseOrZero(acquisitionFee) +
    parseOrZero(miscFees) +
    parseOrZero(unexplained) -
    parseOrZero(rebateLeaseCash) -
    parseOrZero(downPayment) +
    parseOrZero(estimatedLeasePayment)
  );
};

const calcResidualAmount = (msrp, residualPercent) => {
  return parseOrZero(msrp) * (parseOrZero(residualPercent) / 100);
};

const calcSalesTaxAmount = (
  months,
  leasePayment,
  rebateLeaseCash,
  downPayment,
  docFee,
  salesTaxPercent
) => {
  const tax =
    ((parseOrZero(months) * parseOrZero(leasePayment) +
      parseOrZero(rebateLeaseCash) +
      parseOrZero(downPayment) +
      parseOrZero(docFee) -
      parseOrZero(leasePayment)) *
      parseOrZero(salesTaxPercent)) /
    100;
  return tax.toFixed(2);
};

const calcInterestRate = (moneyFactor) => {
  return parseOrZero(moneyFactor) * 2400;
};

const calcLoanAmount = (netCapCost, residualAmount) => {
  return (parseOrZero(netCapCost) - parseOrZero(residualAmount)).toFixed(2);
};

const calcPaymentsTotal = (months, monthlyPayment) => {
  return (parseOrZero(months) * parseOrZero(monthlyPayment)).toFixed(2);
};

const calcMonthlyInterestAmount = (currentBalance, interestRate) => {
  const interest =
    (parseOrZero(currentBalance) * parseOrZero(interestRate)) / 100 / 12;
  const rounded = Math.round(interest * 100) / 100;
  return rounded.toFixed(2);
};

const calcMonthlyPrincipalAmount = (
  monthlyInterestAmount,
  totalPaidForMonth
) => {
  const principal =
    parseOrZero(totalPaidForMonth) - parseOrZero(monthlyInterestAmount);
  const rounded = Math.round(principal * 100) / 100;
  return rounded.toFixed(2);
};

const calcLeasePaymment = (
  loanAmount,
  interestRate,
  months,
  residualAmount
) => {
  const presentValue = parseOrZero(loanAmount);
  const apr = parseOrZero(interestRate);
  const n = parseOrZero(months);

  if (!interestRate) return 0;
  if (!months) return 0;
  const R = apr / 1200;
  const paymentPandI = (presentValue * R) / (1 - Math.pow(1 + R, n * -1));
  // const paymentPandI =
  //     (presentValue * R * Math.pow((1 + R), n))
  //     /
  //     (Math.pow((1 + R), n) - 1);
  const residualPayment =
    (parseOrZero(residualAmount) * (parseOrZero(interestRate) / 100)) / 12;

  return (paymentPandI + residualPayment).toFixed(2);
};

const calcMonthlyPayment = (leasePayment, salesTaxAmount, months) => {
  if (!months) {
    return NaN;
  }
  return (
    parseOrZero(leasePayment) +
    parseOrZero(salesTaxAmount) / parseOrZero(months)
  ).toFixed(2);
};

const calcTotalLeaseCost = (monthlyPayment, downPayment, months) => {
  return (
    parseOrZero(monthlyPayment) * parseOrZero(months) +
    parseOrZero(downPayment)
  ).toFixed(2);
};

export const getDefaults = (values = {}) => {
  // copy the values obect that was passed in

  const defaults = {
    extraPayments: {},

    ...values,
  };

  return defaults;
};

export const getValue = (state, value, values) => {
  if (value) {
    return state[value];
  } else if (values) {
    return values.reduce((acc, cur) => acc + parseOrZero(state[cur]), 0);
  } else {
    return 0;
  }
};

export const calculateAmortization = (v) => {
  /**
   * calculate:
   *  * month (date of payment)
   *  * payment amount (would be the same every month)
   *  * extra amount
   *  * total paid for month
   *  * remaining balance
   *  * amount going towards principal
   *  * amount going towards interest
   *  * LTV (loan-to-value)
   */
  const {
    months,
    monthlyPayment,
    loanAmount,
    startDate: startDateString,
    interestRate,
    purchasePrice,
    extraPayments,
  } = v;

  const newDate = new Date(startDateString);
  const startDate = new Date(
    newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000
  );

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const payments = [...new Array(parseInt(months))]
    .map((_, monthIndex) => monthIndex)
    .reduce((pastMonthlyPayments, currentPaymentMonthIndex) => {
      const currentBalance =
        currentPaymentMonthIndex === 0
          ? loanAmount
          : pastMonthlyPayments.slice(-1)[0].remainingBalance;

      if (parseFloat(currentBalance) <= 0) {
        return pastMonthlyPayments;
      }

      const paymentDate = addMonths(startDate, currentPaymentMonthIndex);
      const month = monthNames[paymentDate.getMonth()];
      const year = paymentDate.getFullYear();

      const extraPaymentAmount = extraPayments[currentPaymentMonthIndex] || 0;
      const totalPaidForMonth = (
        parseOrZero(monthlyPayment) + parseOrZero(extraPaymentAmount)
      ).toFixed(2);

      const monthlyInterestAmount = calcMonthlyInterestAmount(
        currentBalance,
        interestRate
      );
      const monthlyPrincipalAmount = calcMonthlyPrincipalAmount(
        monthlyInterestAmount,
        totalPaidForMonth
      );
      let remainingBalance = (
        parseOrZero(currentBalance) - parseOrZero(monthlyPrincipalAmount)
      ).toFixed(2);

      if (parseFloat(remainingBalance) < 0) {
        remainingBalance = (0).toFixed(2);
      }

      const loanToValue = ((remainingBalance / loanAmount) * 100).toFixed(2);

      const paymentDetails = {
        paymentNumber: currentPaymentMonthIndex + 1,
        month,
        year,
        paymentAmount: monthlyPayment,
        extraPaymentAmount,
        totalPaidForMonth,
        remainingBalance,
        monthlyInterestAmount,
        monthlyPrincipalAmount,
        loanToValue,
      };

      pastMonthlyPayments.push(paymentDetails);

      return pastMonthlyPayments;
    }, []);

  return payments;
};

export const calculateLoan = (v) => {
  //   const v = values.slice();
  const debug = false;

  const interestRate = calcInterestRate(v.moneyFactor);
  v.interestRate = interestRate;
  debug && console.log(`interest rate: ${interestRate}`);


  const residualAmount = calcResidualAmount(v.msrp, v.residualPercent);
  v.residualAmount = residualAmount;
  debug && console.log(`residual: ${residualAmount}`);

  // const interestAmount = this.calcInterestAmount(v.purchasePrice, v.months, v.monthlyPayment);
  // v.interestAmount = interestAmount;

  const discountedMsrp = calcDiscountedMsrp(v.msrp, v.dealerContribution);
  v.discountedMsrp = discountedMsrp;
  debug && console.log(`discounted msrp: ${discountedMsrp}`);

  const finalDealerPrice = calcFinalDealerPrice(v.discountedMsrp, v.tradeIn);
  v.finalDealerPrice = finalDealerPrice;
  debug && console.log(`final dealer price: ${finalDealerPrice}`);

  const netCapCost = calcNetCapCost(
    v.finalDealerPrice,
    v.docFee,
    v.acquisitionFee,
    v.miscFees,
    v.rebateLeaseCash,
    v.downPayment,
    v.estimatedLeasePayment,
    v.unexplained
  );
  v.netCapCost = netCapCost;
  debug && console.log(`net cap: ${netCapCost}`);

  const loanAmount = calcLoanAmount(v.netCapCost, v.residualAmount);
  v.loanAmount = loanAmount;
  debug && console.log(`loan amount: ${loanAmount}`);

  const leasePayment = calcLeasePaymment(
    v.loanAmount,
    v.interestRate,
    v.months,
    v.residualAmount
  );
  v.leasePayment = leasePayment;
  debug && console.log(`lease payment: ${leasePayment}`);

  const salesTaxAmount = calcSalesTaxAmount(
    v.months,
    v.leasePayment,
    v.rebateLeaseCash,
    v.downPayment,
    v.docFee,
    v.salesTaxPercent
  );
  v.salesTaxAmount = salesTaxAmount;
  debug && console.log(`sales tax amount: ${salesTaxAmount}`);

  const monthlyPayment = calcMonthlyPayment(
    v.leasePayment,
    v.salesTaxAmount,
    v.months
  );
  v.monthlyPayment = monthlyPayment;
  debug && console.log(`monthly payment: ${monthlyPayment}`);

  const totalLeaseCost = calcTotalLeaseCost(
    v.monthlyPayment,
    v.downPayment,
    v.months
  );
  v.totalLeaseCost = totalLeaseCost;
  debug && console.log(`total lease cost: ${totalLeaseCost}`);

  // setValues(v);

  return v;

  // this.runCalculation(recursiveCalls + 1);
};
