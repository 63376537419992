import React from "react";
import FormPageTemplate from "../components/FormPageTemplate";
import {
  calculateLoan,
  calculateAmortization,
  getDefaults,
} from "../utils/loanFuncs/vehicleLeaseFuncs";
import ResultsForm from "../components/ResultsForms";
import LoanPageStyles from "../styles/LoanPageStyles";
import SEO from "../components/SEO";

const inputs = [
  // each nested array is a fieldset
  [
    {
      name: "loanName",
      label: "Loan Name",
      required: true,
      placeholder: "",
      autocomplete: "",
      fullWidth: true,
    },
  ],
  [
    {
      name: "msrp",
      label: "MSRP",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "dealerContribution",
      label: "Dealer Contribution",
      type: "number",
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "docFee",
      label: "Doc Fee",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "acquisitionFee",
      label: "Acquisition Fee",
      type: "number",
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "miscFees",
      label: "Misc. Fees",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "estimatedLeasePayment",
      label: "Estimated Lease Payment",
      type: "number",
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "rebateLeaseCash",
      label: "Rebate/Lease Cash",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "residualPercent",
      label: "Residual",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "downPayment",
      label: "Down Payment",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "months",
      label: "Months",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
    },
  ],
  [
    {
      name: "salesTaxPercent",
      label: "Sales Tax",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "moneyFactor",
      label: "Money Factor",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      placeholder: "",
      autocomplete: "",
    },
  ],
];

const computedFields = [
  { label: "MSRP", field: "msrp" },
  { label: "Final dealer price", field: "finalDealerPrice" },
  { label: "Lease Payment", field: "leasePayment" },
  { label: "Sales tax", field: "salesTaxAmount" },
  { label: "Total cost of lease", field: "totalLeaseCost" },
];

const amortizationColumnDefs = [
  {
    label: "#",
    field: "paymentNumber",
    style: {
      minWidth: 50,
    },
    headerCellStyle: {},
    dataCellStyle: {
      padding: "0.5rem 0",
      border: "1px solid var(--blue)",
    },
  },
  {
    label: "Month",
    field: "month",
  },
  {
    label: "Year",
    field: "year",
  },
  {
    label: "Interest",
    field: "monthlyInterestAmount",
    isDollarAmount: true,
  },
  {
    label: "Principal",
    field: "monthlyPrincipalAmount",
    isDollarAmount: true,
  },
  {
    label: "Total",
    field: "totalPaidForMonth",
    isDollarAmount: true,
  },
  {
    label: "Remaining",
    field: "remainingBalance",
    isDollarAmount: true,
  },
  {
    label: "LTV",
    field: "loanToValue",
    isPercentage: true,
  },
];

const formDefaults = {
  loanName: "",
  msrp: 34153,
  dealerContribution: 3853,
  docFee: 250,
  acquisitionFee: 650,
  miscFees: 103.5,
  estimatedLeasePayment: 300,
  rebateLeaseCash: 500,

  residualPercent: 55,
  downPayment: 3000,
  months: 36,
  salesTaxPercent: 6.5,
  moneyFactor: 0.0003,

  startDate: "2021-07-28",
};

const formOptions = {
  requiredFields: inputs
    .flat()
    .filter((i) => i.required)
    .map((i) => i.name),
  calculateLoan,
  calculateAmortization,
  getDefaults,
};

export default function ({ data }) {
  return (
    <>
      <SEO title={"Vehicle Lease"} />
      <LoanPageStyles>
        <FormPageTemplate
          formKey="vehicle-lease"
          formDefaults={formDefaults}
          formOptions={formOptions}
          style={{ marginBottom: 50 }}
          inputs={inputs}
          resultsFormProps={{
            computedFields,
            amortizationColumnDefs,
          }}
          resultsForm={ResultsForm}
        />
      </LoanPageStyles>
    </>
  );
}
